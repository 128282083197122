import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Section from '../components/section/section.js'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs.js'
import { SearchContext } from '../state/search'
import Hr from '../components/hr/hr'
import filterMethods from '../utils/methodsFilter'
import FilterBar from '../components/filterBar/filterBar'
import TemplatesHint from '../components/templatesHint/templatesHint'
import ToolsListing from '../components/toolsListing/toolsListing.js'
import './search.sass'

type Props = {
  location: { search: string }
}

type SearchResult = { item: Method }

const breadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'Search Results', href: '/' },
]

const Search: React.FC<Props> = ({ location }) => {
  const { query, setQuery, results, templatesFound } = useContext(SearchContext)
  const [filters, setFilters] = useState({})

  const [methods, setMethods] = useState(
    results.map((r: SearchResult) => r.item)
  )
  const [showHint, setShowHint] = useState(templatesFound)
  const [displayedQuery, setDisplayedQuery] = useState<string>('')

  useEffect(() => {
    const queryParam = queryString.parse(location.search).q
    setQuery(queryParam || '')

    if (typeof queryParam === 'string') {
      setDisplayedQuery(queryParam)
    }
  }, [location.search])

  useEffect(() => {
    if (displayedQuery !== query) {
      return
    }

    if (displayedQuery.length < 3) {
      setMethods([])
      setShowHint(false)
    } else {
      setMethods(
        filterMethods(
          results.map((r: SearchResult) => r.item),
          filters
        )
      )
      setShowHint(templatesFound)
    }
  }, [filters, setMethods, results, displayedQuery, query, templatesFound])

  return (
    <DefaultLayout
      className="page-search-results"
      seo={{ title: `Search results for ${displayedQuery}` }}
    >
      <Section className="breadcrumbs-section">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Section>
      <Section className="page-search-results__header-section">
        <header
          className={`section-header page-search-results__header section-header--flex${
            methods.length > 0 && !showHint ? ' section-header--listing' : ''
          }`}
        >
          <h2 className="h2 section-headline page-search-results__header-headline">
            {methods.length + (showHint ? 1 : 0)} Results for{' '}
            <strong className="h--decor">
              &quot;{displayedQuery.toUpperCase()}
              &quot;
            </strong>
          </h2>

          {methods.length + (showHint ? 1 : 0) <= 0 && (
            <div className="page-search-results__header-subline">
              Sorry, there are no results matching your search term.
            </div>
          )}
          <Hr />
        </header>
        {showHint && <TemplatesHint className="page-search-results__hint" />}
        {methods.length > 0 && (
          <ToolsListing
            filters={
              <FilterBar
                found={methods.length}
                workflowTags={[]}
                onUpdate={setFilters}
                canSort={false}
              />
            }
            methods={methods}
          />
        )}
        {methods.length + (showHint ? 1 : 0) === 0 && (
          <div className="page-search-results__suggestions-container">
            You can try the following:
            <ul className="page-search-results__suggestions-list">
              <li className="page-search-results__suggestions-list-item">
                Make sure all words are spelled correctly.
              </li>
              <li className="page-search-results__suggestions-list-item">
                Try other search terms.
              </li>
              {/* <li className="page-search-results__suggestions-list-item">Make use of the suggested search terms.</li> */}
              <li className="page-search-results__suggestions-list-item">
                Try more general search terms.
              </li>
            </ul>
          </div>
        )}
      </Section>
    </DefaultLayout>
  )
}

export default Search
